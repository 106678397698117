import { Radio, Select, InputNumber, Form, Input, DatePicker, Modal } from 'antd'
import ModalBase from '../../ModalBase/ModalBase';
import { GskChargeService } from '../../../Services/GskChargeService';
import { AccrualType, GskAccrualModel, NewGskAccrualModel } from '../../../types/GskChargeTypes';
import { RepeatIntervalType } from '../../../store/enum/repeatIntervalType';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs'
import { useEffect, useMemo } from 'react';
import { ElectricAccrualConfigModel } from '../../../types/MeterTypes';
import { AccrualElectricPartial, getElectricAccrualConfig } from './AccrualElectricPartial';
import FloatInput from '../../Controls/FloatInput';
import useLoading from '../../../utils/hooks/useLoading';
import DeleteButton from '../../Controls/DeleteButton';
import { NumInputValidator } from '../../../utils/NumInputValidator';

export interface AccrualFormData extends Partial<ElectricAccrualConfigModel> {
  accrualType: AccrualType;
  repeatType: RepeatIntervalType;
  repeatInterval: number;
  name: string;
  amount?: number;
  startPeriod: Date;
  endPeriod: Date | null;
}
interface OwnProps {
	gskId: number;
	accrual: GskAccrualModel | null;
	closeDialog: CallableFunction;
	onFinished: (v: GskAccrualModel | undefined) => void;
}

const EditAccrualModal = ({ closeDialog, gskId, onFinished, accrual }: OwnProps) => {
	const [isLoading, load] = useLoading();
	const isNew = !accrual;
	const [form] = useForm<AccrualFormData>();
  const accrualType: AccrualType = Form.useWatch('accrualType', form);
	const disabledChangeAccuralType = useMemo(() => accrual?.accrualType === AccrualType.ELECTRIC, [accrual?.accrualType]);

	const accuralTypeHelp = useMemo(() =>
	{
		switch (accrualType) {
			case AccrualType.ONETIME:
				return 'Подходит для разовых сборов, например "Уборка снега", "Субботник" и тп';
			case AccrualType.REPEAT:
				return 'Начисления c определенной периодичностью, например "Членский взнос", "Охрана" и тп';
			case AccrualType.ELECTRIC:
				return 'Учет по счетчикам';
			default: 
				return undefined;
		}
	}, [accrualType])

	useEffect(() => {
		if (accrualType === AccrualType.ELECTRIC) {
			form.setFieldValue('name', 'Электроэнергия');
		}
	}, [accrualType, form]);

	const handleOk = async (data: AccrualFormData) => {
		const req: NewGskAccrualModel = {
			accrualType: data.accrualType,
			amount: data.amount,
			repeatType: data.accrualType === AccrualType.ONETIME ? null : data.repeatType,
			repeatInterval: data.accrualType === AccrualType.ONETIME ? null : data.repeatInterval,
			name: data.name,
			startPeriod: data.startPeriod,
			endPeriod: data.endPeriod,
			electricAccrualConfig: getElectricAccrualConfig(data),
		}

		if (isNew) {
			const result = await load(GskChargeService.addAccrual(gskId, req));
			if (result) {
				closeDialog();
				onFinished(result);
			}
		} else {
			const result = await load(GskChargeService.editAccrual(gskId, accrual.id, req));
			if (result) {
				closeDialog();
				onFinished(result);
			}
		}
	}
	const handleDelete = () => {
		if (!accrual) {
			return;
		}
		Modal.confirm({
			title: "Удаление",
			content: <>Точно удалить начилсение <b>{accrual.name}</b>? Сформированные начисления <b>не будут</b> удалены и останутся доступными для оплаты</>,
			okText: "Удалить",
			onOk: async () => {
				await GskChargeService.delAccrual(gskId, accrual.id);
				closeDialog();
				await onFinished(undefined);
			}
		});
	}

	return (
		<ModalBase 
			closeDialog={closeDialog}
			title={(!!accrual ? "Изменить" : "Добавить") + " начисление"}
			okButtonHandler={() => form.submit()}
			isLoading={isLoading}
			leftFooterCell={!isNew && <DeleteButton onClick={handleDelete}/>}
			width={600}
		>
			<Form
				form={form}
				labelCol={{span: 8}}
				wrapperCol={{span:16}}
				size='small'
				initialValues={{
					accrualType: accrual?.accrualType ?? AccrualType.ONETIME,
					repeatType: accrual?.repeatType ?? RepeatIntervalType.Month,
					repeatInterval: accrual?.repeatInterval ?? 1,
					name: accrual?.name ?? '',
					amount: accrual?.amount ?? 0,
					startPeriod: accrual?.startPeriod ? dayjs(accrual.startPeriod) : undefined,
					endPeriod: accrual?.endPeriod ? dayjs(accrual.endPeriod) : undefined,
					
					withNightTarif: accrual?.electricAccrualConfig?.withNightTarif ?? false,
					initDayValue: accrual?.electricAccrualConfig?.initDayValue ?? 0,
					initNightValue: accrual?.electricAccrualConfig?.initNightValue ?? 0,
					dayMeterValue: accrual?.electricAccrualConfig?.dayMeterValue ?? 0,
					nightMeterValue: accrual?.electricAccrualConfig?.nightMeterValue ?? 0,
					dayUnitPrice: accrual?.electricAccrualConfig?.dayUnitPrice,
					nightUnitPrice: accrual?.electricAccrualConfig?.nightUnitPrice,
				}}
				onFinish={handleOk}
			>
				<Form.Item 
					name='name'
					label='Название'
					tooltip='Будет отображаься в платежных документах'
					rules={[{required: true, message: 'Введите значение'}, {max: 20, message: 'Слишком длинное'}]}
				>
					<Input disabled={accrualType === AccrualType.ELECTRIC}/>
				</Form.Item>
				<Form.Item name='accrualType' label='Вид начисления' help={accuralTypeHelp} rules={[{required: true, message: 'Выбирете значение'}]}>
					<Radio.Group disabled={disabledChangeAccuralType}>
						<Radio value={AccrualType.ONETIME}>Разово</Radio>
						<Radio value={AccrualType.REPEAT}>Повторяется</Radio>
						<Radio value={AccrualType.ELECTRIC}>Электроэнергия</Radio>
					</Radio.Group>
				</Form.Item>
				{accrualType === AccrualType.REPEAT &&
				<Form.Item name='repeatInterval' label='Повторять каждые' rules={[{required: accrualType === AccrualType.REPEAT, message: 'Введите значение'}]}>
					<InputNumber
						addonAfter={
							<Form.Item name='repeatType' noStyle rules={[{required: accrualType === AccrualType.REPEAT, message: 'Введите значение'}]}>
								<Select<RepeatIntervalType> style={{width: 100, textAlign: 'left'}} size='small'>
									<Select.Option value={RepeatIntervalType.Month}>месяц(а)</Select.Option>
									<Select.Option value={RepeatIntervalType.Year}>год(а)</Select.Option>
								</Select>
							</Form.Item>} 
					/>
				</Form.Item>}
				<Form.Item 
					name='startPeriod'
					label='Начало периода'
					tooltip='С этой даты будут создаваться начисления'
					rules={[{required: true, message: 'Введите значение'}]}
				>
					<DatePicker format="DD.MM.YYYY" />
				</Form.Item>
				{accrualType === AccrualType.ELECTRIC && <AccrualElectricPartial config={accrual?.electricAccrualConfig} form={form}/> }
				{(accrualType === AccrualType.ONETIME || accrualType === AccrualType.REPEAT) &&
				<>
					<Form.Item name='endPeriod' tooltip='После этой даты не будут создаваться начисления. Можно не заполнять' label='Конец периода'>
						<DatePicker format="DD.MM.YYYY" />
					</Form.Item>
					<Form.Item name='amount' label='Сумма' rules={[NumInputValidator(0.01, 1000000, true)]}>
						<FloatInput addonAfter='₽'/>
					</Form.Item>
				</>}
			</Form>
		</ModalBase>
	);
}

export default EditAccrualModal;

