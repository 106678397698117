import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function GskRolesPage() {
  return <>
    <h1>Роли пользователей</h1>
    <p>На сайте МойГСК.рф реализована ролевая модель для пользователей ГСК. Вы можете распределить обязанности между пользователям и 
      выдать им соответствующие привилегии</p>
    

    <p>Ролей всего 5: <b>Председатель</b>, <b>Администратор</b>, <b>Бухгалтер</b>, <b>Редактор карт</b>, <b>Член ГСК</b></p>
    
    <h2>Член ГСК</h2>
    <ul>
      <li>Доступ к членской книжке новостям и документам</li>
      <li>Чтение новостей. Может оставлять комментарии и лайки</li>
      <li>Скачивание документов</li>
      <li>Видит <b>только свои долги</b> и может оплатить их онлайн (если <Link to='../gskBilling/yookassa'>биллинг настроен</Link>)</li>
      <li>Не видит личные данные других участников ГСК</li>
      <li>Может участвовать в голосованиях</li>
      <li>Будет получать уведомления на почту при появлении новых начислений и новостей</li>
    </ul>

    <h2>Бухгалтер</h2>
    
    <ul>
      <li>Имеет все привилегии <b>Члена ГСК</b></li>
      <li>Полный доступ в раздел <b>Начисления</b>. Настройка начислений. Формирование платежей</li>
      <li>Видит личные данные всех пользователей ГСК</li>
      <li>Видит долги, начисления и платежи всех пользователей ГСК. Может их изменять</li>
      <li>Доступ к разделу <b>Счетчики</b>. Может добавлять\изменять показания</li>
      <li>Доступ к разделу <b>Отчеты</b></li>
    </ul>

    <h2>Редактор карт</h2>
    <p>Функционал редактирования карт ГСК. В разработке, пока не доступен.</p>

    <h2>Председатель и Администратор</h2>
    
    <p>При регистрации ГСК, пользователю назначаются роли <b>Председатель</b> и <b>Администратор</b> для этого ГСК.
    Это роли с максимально возможными правами. Пользователи с этими правами могу назначать роли другим пользователям (об этом ниже)</p>
    <p>Отличие заключается в том, что <b>Председатель</b> может быть только один, а <b>Администраторов</b> несколько</p>
    <p><b>Председатель</b> может забрать права у <b>Администратора</b>, но не наоборот</p>
    <p>Подробнее о смене председателя можно прочитать <Link to="../changeGskChairman">здесь</Link> </p>
    
    <ul>
      <li>Имеет все привилегии <b>Члена ГСК</b></li>
      <li>Имеет все привилегии <b>Бухгалтера</b></li>
      <li>Имеет все привилегии <b>Редактора карт</b></li>
      <li>Может приглашать пользователей в ГСК и удалять их</li>
      <li>Создавать новости и голосования</li>
      <li>Добавлять\удалять документы</li>
      <li>Добавлять\изменять\удалять Боксы</li>
      <li>Добавлять\изменять\удалять Счетчики</li>
    </ul>

    <h1>Иерархия ролей</h1>
    <p>Роль <b>Председатель</b> включает в себя все остальные роли (<b>Администратор</b>, <b>Бухгалтер</b>, <b>Редактор карт</b>, <b>Член ГСК</b>)</p>
    <p>Роль <b>Администратор</b> включает в себя все роли, кроме роли <b>Председатель</b> (<b>Бухгалтер</b>, <b>Редактор карт</b>, <b>Член ГСК</b>)</p>
    
    <p>Роль <b>Бухгалтер</b> включает в себя роль  <b>Член ГСК</b></p>
    <p>Роль <b>Редактор карт</b> включает в себя роль  <b>Член ГСК</b></p>
    <p>Роль <b>Член ГСК</b> назначается всем пользователям, которые приняли приглашение для вступления в ГСК. Эта роль удаляется автоматически при 
    отвязке пользователя от всех его боксов в ГСК</p>
    
    <h1>Назначение ролей</h1>
    <p>Роли могу назначать только пользователи с ролями <b>Председатель</b> или <b>Администратор</b></p>
    <p>Сначала необходимо <Link to='../inviteGskUser'>пригласить пользователя</Link> (привязать пользователя к боксу).</p>
    <p>Нажмите кнопку <b>Редактировать</b><EditOutlined/> в строке с боксом, к которому привязан пользователь. Выберите нужные роли и нажмите <b>Ок</b></p>

    <p>Роль <b>Председатель</b> нельзя назначить таким образом. Подробнее о смене председателя можно прочитать <Link to="../changeGskChairman">здесь</Link></p>
    {/* TODO <h1>Матрица доступа</h1> */}
  </>
}