import { BoxModelWithMeters, BoxesWitMetersRequest, EditElectricMeterModel, ElectricConsumptionReportRequest, ElectricConsumptionReportRow, ElectricMeterModel, ElectricMeterReadingsModel, ElectricMetersReadingsSum, MeterReadingsRequest, NewElectricMeterModel, NewElectricMeterReadingsModel, TopElectricConsumptionsReportModel } from "../types/MeterTypes";
import { DatesRangeModel, PagedResult } from "../types/dto";
import { Fetch, FetchNoResp } from "../utils/fetch";

export const ElectricMetersService = {
	getBoxMeters: async (gskId: number, boxId: number): Promise<ElectricMeterModel[]> => {
		return (await Fetch<ElectricMeterModel[]>(`/api/gsks/${gskId}/boxes/${boxId}/electricMeters`, 'GET')) ?? [];
	},
	getMeter: async (gskId: number, meterId: number): Promise<ElectricMeterModel | undefined> => {
		return await Fetch<ElectricMeterModel>(`/api/gsks/${gskId}/electricMeters/${meterId}`, 'GET');
	},
	getBoxesWithMeters: async (gskId: number, req: BoxesWitMetersRequest): Promise<PagedResult<BoxModelWithMeters> | undefined> => {
		return await Fetch<PagedResult<BoxModelWithMeters>>(`/api/gsks/${gskId}/electricMeters/boxesWithMeters`, 'POST', req);
	},
	addMeter: async (gskId: number, data: NewElectricMeterModel): Promise<ElectricMeterModel | undefined> => {
		return await Fetch<ElectricMeterModel>(`/api/gsks/${gskId}/electricMeters`, 'PUT', data);
	},
	editMeter: async (gskId: number, meterId: number, data: EditElectricMeterModel): Promise<ElectricMeterModel | undefined> => {
		return await Fetch<ElectricMeterModel>(`/api/gsks/${gskId}/electricMeters/${meterId}`, 'PATCH', data);
	},
	deleteMeter: async (gskId: number, meterId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/electricMeters/${meterId}`, 'DELETE')
	},
	restoreMeter: async (gskId: number, meterId: number): Promise<ElectricMeterModel | undefined> => {
		return await Fetch<ElectricMeterModel>(`/api/gsks/${gskId}/electricMeters/${meterId}/restore`, 'POST');
	},
	getReadings: async (gskId: number, meterId: number, req: MeterReadingsRequest): Promise<PagedResult<ElectricMeterReadingsModel> | undefined> => {
		return await Fetch<PagedResult<ElectricMeterReadingsModel>>(`/api/gsks/${gskId}/electricMeters/${meterId}/readings`, 'POST', req);
	},
	addMeterReadings: async (gskId: number, meterId: number, data: NewElectricMeterReadingsModel): Promise<ElectricMeterReadingsModel | undefined> => {
		return await Fetch<ElectricMeterReadingsModel>(`/api/gsks/${gskId}/electricMeters/${meterId}/readings`, 'PUT', data);
	},
	deleteMeterReadings: async (gskId: number, meterId: number, readingsId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/electricMeters/${meterId}/readings/${readingsId}`, 'DELETE');
	},

	getReadingsDateInterval: async (gskId: number): Promise<DatesRangeModel | undefined> => {
		return await Fetch<DatesRangeModel>(`/api/gsks/${gskId}/electricMeters/report/readingsDateInterval`, 'GET');
	},
	getReadingsSumOnDate: async (gskId: number, date: Date): Promise<ElectricMetersReadingsSum | undefined> => {
		return await Fetch<ElectricMetersReadingsSum>(`/api/gsks/${gskId}/electricMeters/report/readingsSumOnDate/${new Date(date).toUTCString()}`, 'GET');
	},
	getTopConsumptionsChartData: async (gskId: number, startDate: Date, endDate: Date, top: number): Promise<TopElectricConsumptionsReportModel | undefined> => {
		return await Fetch<TopElectricConsumptionsReportModel>(`/api/gsks/${gskId}/electricMeters/report/topConsumptionsChartData`, 'GET', {startDate: startDate.toUTCString(), endDate: endDate.toUTCString(), top});
	},
	getConsumptionPage: async (gskId: number, req: ElectricConsumptionReportRequest): Promise<PagedResult<ElectricConsumptionReportRow> | undefined> => {
		return await Fetch<PagedResult<ElectricConsumptionReportRow>>(`/api/gsks/${gskId}/electricMeters/report/consumptionPage`, 'POST', req);
	},
}