import { useForm } from "antd/es/form/Form";
import ModalBase from "../../ModalBase/ModalBase";
import { Form } from "antd";
import { ElectricMeterModel, ElectricMeterReadingsModel, METER_VALUE_LIMIT, NewElectricMeterReadingsModel } from "../../../types/MeterTypes";
import { ElectricMetersService } from "../../../Services/ElectricMetersService";
import { roundFloat, toFloatString } from "../../../utils/numberHelper";
import { useMemo } from "react";
import FloatInput from "../../Controls/FloatInput";
import useLoading from "../../../utils/hooks/useLoading";
import { PagedResult } from "../../../types/dto";
import { GskBox } from "../../../types/GskTypes";
import { DayIcon, NightIcon } from "../../icons";

interface FormData {
  dayValue: number;
  nightValue: number | null | undefined;
}

interface Props {
  box: GskBox;
  meter: ElectricMeterModel;
  setReadings?: React.Dispatch<React.SetStateAction<PagedResult<ElectricMeterReadingsModel>>>;
  refreshMeter: () => void;
  closeDialog: CallableFunction;
}

export default function NewElectricMeterReadingModal({box, meter, setReadings, refreshMeter, closeDialog}: Props) {
  const [form] = useForm<FormData>();
  const [isLoading, save] = useLoading();

  const dayValue = (Form.useWatch('dayValue', form) ?? -1);
  const nightValue = (Form.useWatch('nightValue', form) ?? -1);
  const dayDiff = useMemo(() => dayValue > METER_VALUE_LIMIT ? undefined : dayValue - meter.lastDayValue, [dayValue, meter.lastDayValue]);
  const nightDiff = useMemo(() => 
    nightValue > METER_VALUE_LIMIT || nightValue === null || nightValue === undefined ? undefined : nightValue - meter.lastNightValue
  , [meter.lastNightValue, nightValue]);
  

  const handleOk = async (data: FormData) => {
    const req: NewElectricMeterReadingsModel = {
      dayValue: roundFloat(data.dayValue, 3),
      nightValue: data.nightValue !== null && data.nightValue !== undefined ? roundFloat(data.nightValue, 3) : null,
    };
    const resp = await save(ElectricMetersService.addMeterReadings(box.gskId, meter.id, req));
    if (resp) {
      if (setReadings) {
        setReadings(prev => ({...prev, items: [resp, ...prev.items]}))
      }
      refreshMeter();
      closeDialog();
    }
  }

  const checkValue = (value: number | null | undefined = null, prevValue: number) => {
    if (value === null || value === undefined) {
      return Promise.reject('Введите значение');
    }

    if (value < 0) {
      return Promise.reject('Меньше нуля');
    }

    if (value < prevValue) {
      return Promise.reject(<>Meньшe предудыщего<br/> значения ({prevValue})</>);
    }

    if (value > METER_VALUE_LIMIT) {
      return Promise.reject(`Больше ${toFloatString(METER_VALUE_LIMIT)}`);
    }

    return Promise.resolve('');
  };

  return (<ModalBase
    closeDialog={closeDialog}
    title={'Новое показание (Бокс №' + box.num + ')'}
    okButtonHandler={() => form.submit()} isLoading={isLoading} width={500}
  >
    <Form
      form={form}
      layout="inline"
      size="small"
      initialValues={{
        id: meter?.id,
        num: meter?.num ?? '',
        withNightTarif: meter?.withNightTarif ?? false,
        initDayValue: meter.lastDayValue,
        initNightValue: meter.lastNightValue,
      }}
      onFinish={handleOk}
    >
      <Form.Item 
        label={<>День<DayIcon/></>}
        name='dayValue'
        rules={[{required: true, validator: (_, v) => checkValue(v, meter.lastDayValue)}]}
        help={dayDiff !== undefined && dayDiff >= 0 ? <>+{toFloatString(dayDiff)} кВт</> : undefined}
      >
        <FloatInput 
          placeholder={meter.lastDayValue.toString()}
          addonAfter='кВт'
          style={{width: 130}} 
        />
      </Form.Item>
      {meter.withNightTarif && 
      <Form.Item
        label={<>Ночь<NightIcon/></>}
        name='nightValue'
        rules={[{required: true, validator: (_, v) => checkValue(v, meter.lastNightValue)}]}
        help={nightDiff !== undefined && nightDiff >= 0 ? <>+{toFloatString(nightDiff)} кВт</> : undefined}
      >
        <FloatInput 
          placeholder={meter.lastNightValue.toString()}
          addonAfter='кВт'
          style={{width: 130}}
        />
      </Form.Item>
      }
    </Form>
  </ModalBase>)
}
     