import { Link } from "react-router-dom"

export default function ElectricReportPage(){
  return <>
    <h1>Настройка начисления 'Электроэнергия'</h1>
    <p>Для корректной работы отчета перейдите в раздел <b>Начисления</b> и нажмите кнопку <b>Добавить</b></p>
    <p>В появившемся окне выберите вид начисления <b>Электроэнергия</b>, заполните оставшиеся поля и нажмите <b>Ок</b></p>
    {/* TODO подробнее о создании начислений можно прочитать здесь */}
    <h1>Данные для отчета</h1>
    <p>Отчет формируется на основании показаний счетчиков, которые можно внести в разделе <b>Боксы / Счетчики</b>.
     Если показания счетчиков не были внесены отчет не будет построен</p>
    {/* TODO подробнее о создании счетчиков можно прочитать здесь */}
    <h1>Кому доступен отчет?</h1>
    <p>Отчет доступен пользователям с <Link to='../gskRoles'>ролью</Link> <b>Бухгалтер</b> и выше. Остальные пользователи не увидят ссылку на отчет и не смогут получить из него данные.</p>
  </>
}