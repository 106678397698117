import { Link } from "react-router-dom";

export default function ChangeCharimanPage() {
  return <>
    <h1>Смена председателя ГСК</h1>
    <p>Запрос на смену председателя может отправить только пользователь с <Link to='../gskRoles'>ролью</Link> <b>Председатель</b></p>
    <p>Передать права председателя можно только члену ГСК. У пользователя должен быть привязан хотя бы один бокс в этом ГСК</p>
    <p>Зайдите в свой ГСК. Откройте меню пользователя (наведите на Ваше ФИО в верхнем правом углу), выберите <b>название ГСК</b> / <b>Сменить председателя</b>.
    В появившемся окне укажите Члена ГСК, которому хотите передать права. После нажатия на кнопку <b>Ок</b> выбранному пользователю на почтовый ящик
    придет предложение 
    принять права председателя. Он может отказать. В случае согласия роль <b>Председатель</b> будет удалена у Вас и назначена выбранному пользователю.
    </p>

    <p>В экстренных случаях (не дееспособность, смерть, и тп) для смены председателя обратитесь в службу поддержки <a href="mailto:moygarajrf@yandex.ru">moygarajrf@yandex.ru</a></p>
  </>
}