import React from "react"
import './divider.scss'

interface DividerFancyProps {
  children?: React.ReactNode;
  size?: number;
  borderThickness?: number;
  gap?: number;
  lineWidth?: string;
  color?: string;
}
export function DividerFancy({ children, size=2, lineWidth='10vw', borderThickness = 3, gap=10, color = "#C44D58" }: DividerFancyProps) {
  let style = { "--w": lineWidth, "--c": color, "--b":borderThickness+'px', "--g": gap+'px' } as React.CSSProperties;
  const el = React.createElement('h'+size, {
    style,
    className: 'fancy',
    children
  })
  return <div style={{display: 'block', width: '100%'}}>{el}</div>
}

interface DividerHrProps {
  children?: string;
  color?: string;
}
export function DividerHr({ children, color = "#0B486B" }: DividerHrProps) {
  let style = { "--c": color } as React.CSSProperties;
  return <hr className="hr-text" style={style} data-content={children}/>
}