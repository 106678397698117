import { EditOutlined, LeftOutlined, QuestionCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Alert, Card, DatePicker, Grid, Space, Spin, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useLoading from "../../utils/hooks/useLoading";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import { ElectricMetersService } from "../../Services/ElectricMetersService";
import useCurrentGsk from "../Gsk/useCurrentGsk";
import { Gsk } from "../../types/GskTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ElectricAccrualConfigModel, ElectricConsumptionReportRow, ElectricMetersReadingsSum } from "../../types/MeterTypes";
import { toMoneyString } from "../../utils/moneyHelper";
import { DatesRangeModel, EmptyPagedResult, PagedResult } from "../../types/dto";
import dayjs from 'dayjs'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { ShowError } from "../../components/Notifications/Notifications";
import IconButton from "../../components/IconButton/IconButton";
import { useDialog } from "../../utils/useDialog";
import { EditGksElectricMeterModal } from "../../components/Gsk/Meters/EditGksElectricMeterModal";
import { GskChargeService } from "../../Services/GskChargeService";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd/lib";
import { DividerHr } from "../../components/Divider/Divider";
import { DayIcon, NightIcon } from "../../components/icons";

function RenderTopChartOptions(
  top: number,
  startPeriod: string,
  endPeriod: string,
  boxNums: string[],
  dayValues: number[],
  withNightTarif: boolean,
  bigScreen: boolean,
  nightValues?: number[],
) {
  let result = {
    chart: {
      type: 'column'
    },
    title: {
      text: `ТОП ${top} портебителей`
    },
    subtitle: {
      text: `за период с ${startPeriod} по ${endPeriod}`
    },
    xAxis: {
      categories: boxNums.map(n => `Бокс №${n}`),
      labels: {
        autoRotation: [-45, -90],
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      },
      crosshair: true,
      accessibility: {
        description: 'Боксы'
      }
    },
    yAxis: {
      min: 0,
      title: {
          text: 'Потребление, кВт'
      }
    },
    plotOptions: {
      series: {
          stacking: 'normal',
          dataLabels: {
              enabled: bigScreen
          }
      }
    }, 
    tooltip: {
      valueSuffix: ' кВт'
    },
    legend: {
      enabled: false
    },
    
    series: [
      {
        name: 'День',
        color: '#ebe534',
        data: dayValues,
      },
      
    ]
  }

  if (withNightTarif && nightValues) {
    result.legend.enabled = true;
    result.series.push({
      name: 'Ночь',
      color: '#4b9ed1',
      data: nightValues,
    })
  }
  return result;
}

export default function ElectricMetersReportPage() {
  const gsk = useCurrentGsk();

	return (<Spin spinning={!gsk}>
		{gsk && <Page gsk={gsk} />}
	</Spin>)
}

const CONSUMPTIONS_REPORT_TOP = 15;
const PAGE_SIZE = 20;
interface PageProps {
	gsk: Gsk;
}
const Page = ({ gsk }: PageProps) => {
  const nav = useNavigate();
  const {sm} = Grid.useBreakpoint();
  const [isLoading, load] = useLoading();
  const [gskMeter, setGskMeter] = useState<ElectricMetersReadingsSum | undefined>();
  const [electricAccrualConfig, setElectricAccrualConfig] = useState<ElectricAccrualConfigModel | undefined>();
  const [datesInterval, setDatesInterval] = useState<DatesRangeModel | undefined>();
  const [startInterval, setStartInterval] = useState<Date | undefined>(undefined);
  const [endInterval, setEndInterval] = useState<Date | undefined>(undefined);
  const [startPeriodReadings, setStartPeriodReadings] = useState<ElectricMetersReadingsSum | undefined>();
  const [endPeriodReadings, setEndPeriodReadings] = useState<ElectricMetersReadingsSum | undefined>();
  const [errors, setErrors] = useState<React.ReactNode[]>([]);


  const [topConsumptionsOptions, setTopConsumptionsOptions] = useState<any | undefined>();
  const [consumption, setConsumption] = useState<ElectricMetersReadingsSum | undefined>();

  const [page, setPage] = useState<PagedResult<ElectricConsumptionReportRow>>(EmptyPagedResult<ElectricConsumptionReportRow>(PAGE_SIZE));

  const columns: ColumnsType<ElectricConsumptionReportRow> = useMemo(() => {
    let result: ColumnsType<ElectricConsumptionReportRow> = [
      {
        title: 'Бокс, №',
        key: 'boxNum',
        dataIndex: 'boxNum',
        width: 50
      },
      {
        title: 'Счетчик, №',
        key: 'meterNum',
        dataIndex: 'meterNum',
        width: 50
      },
      {
        title: 'День',
        key: 'dayValue',
        dataIndex: 'dayValue',
        width: 50,
        render: (_, row) => <>{toMoneyString(row.dayValue, false)} <span style={{color: 'lightgray'}}>(+{toMoneyString(row.dayConsumption, false)})</span></>
      },
    ];
    if (electricAccrualConfig?.withNightTarif){
      result.push({
        title: 'Ночь',
        key: 'nightValue',
        dataIndex: 'nightValue',
        width: 50,
        render: (_, row) => <>{toMoneyString(row.nightValue ?? 0, false)} <span style={{color: 'lightgray'}}>(+{toMoneyString(row.nightConsumption ?? 0, false)})</span></>
      })
    }
    return result;
  }, [electricAccrualConfig?.withNightTarif]);

  const loadPage = useCallback(async (page: number, pageSize: number) => {
    if (!startInterval || !endInterval) {
      return;
    }
    const resp = await load(ElectricMetersService.getConsumptionPage(gsk.id, { page, pageSize, dateStart: startInterval, dateEnd: endInterval }));
    if (resp) {
      setPage(resp)
    }
  }, [startInterval, endInterval, load, gsk.id]);

  const [editGskMeterDialog, openEditGskMeterDialog] = useDialog(close => 
    <EditGksElectricMeterModal closeDialog={close} gskId={gsk.id} config={electricAccrualConfig!} onFinished={setElectricAccrualConfig}
  />)

  const handleEditGskMeterClick = () => {
    if (!electricAccrualConfig) {
      ShowError("Начисление 'Электроэнергия' не найдено.", "Добавьте его на вкладке 'Начисления' и повторите попытку")
      return;
    }
    openEditGskMeterDialog()
  }

  useEffect(() => {
    if (!startPeriodReadings || !endPeriodReadings) {
      setConsumption(undefined);
      return;
    }
    const dayValue = endPeriodReadings.dayValue - startPeriodReadings.dayValue;
    const nightValue = endPeriodReadings.nightValue !== undefined ? endPeriodReadings.nightValue - (startPeriodReadings.nightValue ?? 0) : undefined;
    setConsumption({dayValue, nightValue});
  }, [endPeriodReadings, startPeriodReadings]);

  useEffect(() => {
    if (!startInterval || !endInterval) {
      return;
    }
    const fetch = async () => {
      const resp = await load(ElectricMetersService.getTopConsumptionsChartData(gsk.id, startInterval, endInterval, CONSUMPTIONS_REPORT_TOP));
      if (!resp) {
        ShowError("Ошибка при получении данных отчета 'ТОП потребителей'");
        return;
      }
      setTopConsumptionsOptions(
        RenderTopChartOptions(
          CONSUMPTIONS_REPORT_TOP,
          dayjs(startInterval).format("DD.MM.YYYY"),
          dayjs(endInterval).format("DD.MM.YYYY"),
          resp.boxNums,
          resp.dayValues,
          electricAccrualConfig?.withNightTarif ?? false,
          sm ?? false,
          resp.nightValues ?? undefined
        )
      );
      loadPage(1, PAGE_SIZE);
    }
    fetch();
  }, [electricAccrualConfig?.withNightTarif, endInterval, gsk.id, load, loadPage, sm, startInterval]);

  useEffect(() => {
    if (!startInterval) {
      setStartPeriodReadings(undefined);
      return;
    }
    const fetch = async () => {
      setStartPeriodReadings(await load(ElectricMetersService.getReadingsSumOnDate(gsk.id, startInterval)));
    }
    fetch();
  }, [gsk.id, load, startInterval]);

  useEffect(() => {
    if (!endInterval) {
      setEndPeriodReadings(undefined);
      return;
    }
    const fetch = async () => {
      setEndPeriodReadings(await load(ElectricMetersService.getReadingsSumOnDate(gsk.id, endInterval)));
    }
    fetch();
  }, [gsk.id, load, endInterval]);

 
  useEffectOnce(() => {
    const fetch = async () => {
      const resp = await load(GskChargeService.getElectricAccrualConfig(gsk.id))
      if (resp) {
        setElectricAccrualConfig(resp);
      } else {
        setErrors(prev => [...prev,
          <>Для работы отчета необходимо настроить начисление "Электроэнергия" в разделе <Link to="../accruals">Начисления</Link></>]
        )
      }
    }
    fetch();
  });

  useEffect(() => {
    if (!electricAccrualConfig) {
      setGskMeter(undefined);
      return;
    }
    setGskMeter({
      dayValue: Math.max(electricAccrualConfig.dayMeterValue, electricAccrualConfig.initDayValue),
      nightValue: electricAccrualConfig.nightMeterValue !== undefined && electricAccrualConfig.initNightValue !== undefined ? 
        Math.max(electricAccrualConfig.nightMeterValue, electricAccrualConfig.initNightValue) : undefined
    })
  }, [electricAccrualConfig])

  useEffectOnce(() => {
    const fetch = async () => {
      const resp = await load(ElectricMetersService.getReadingsDateInterval(gsk.id))
      if (!resp) {
        setErrors(prev => [...prev,
          <>Отчет строится на основании показаний счетчиков, которые можно внести в разделе <Link to="../Meters">Боксы / Счетчики</Link></>]
        )
        return;
      }
      if (resp?.startDate) {
        resp.startDate = new Date(resp?.startDate);
      }
      if (resp?.endDate) {
        const endDate = new Date(resp.endDate);
        resp.endDate = endDate;
        setEndInterval(endDate);
        const startDate = new Date(endDate);
        startDate.setMonth(endDate.getMonth() - 1);
        setStartInterval(startDate);
      }
      setDatesInterval(resp);
    }
    fetch();
  });

  return <Card
    title={
      <Space>
        <LeftOutlined onClick={() => nav(-1)} title="Назад" style={{cursor: 'pointer', color: '#1677ff'}} />
        <span style={{fontSize: '1.6em'}}>Отчет по электроэнергии</span>
      </Space>
    }
    extra={<IconButton icon={<QuestionCircleOutlined />} title='Справка' onClick={() => nav('/help/reports/electric')}/>}
    loading={isLoading}
  >  
  {errors.length > 0 ? errors.map((e, ind) => <Alert key={ind} type="error"  banner message={e} showIcon/>)
  : <>
    <DividerHr>Показания общего счетчика</DividerHr>
    <Space>
      <MeterReadingsBlock withNightTarif={electricAccrualConfig?.withNightTarif} readings={gskMeter} />
      <IconButton icon={<EditOutlined/>} onClick = {handleEditGskMeterClick} title='Изменить показания общего счетчика'/>
    </Space>
    <DividerHr>Показания счетчиков боксов</DividerHr>
      <table><tbody>
        <tr>
          <td style={{minWidth: !sm ? '50%' :130, verticalAlign: 'top'}}>
            <span style={{width: 20}}>c&nbsp;</span>
            <DatePicker 
              style={{width: 120}}
              onChange={v => setStartInterval(v?.toDate()) }
              value={startInterval ? dayjs(startInterval) : undefined}
              minDate={dayjs(datesInterval?.startDate)}
              maxDate={dayjs(datesInterval?.endDate)}
              format="DD.MM.YYYY"/>
            <br/>
            <MeterReadingsBlock withNightTarif={electricAccrualConfig?.withNightTarif} readings={startPeriodReadings}/>
          </td>
          <td style={{width: !sm ? '50%' :'550px', verticalAlign: 'top'}}>
          <span style={{width: 20}}>по&nbsp;</span>
            <DatePicker
              style={{width: 120}}
              onChange={v => setEndInterval(v?.toDate()) }
              value={endInterval ? dayjs(endInterval) : undefined}
              minDate={dayjs(datesInterval?.startDate)}
              maxDate={dayjs(datesInterval?.endDate)}
              format="DD.MM.YYYY"
            />
            <br/>
            <MeterReadingsBlock withNightTarif={electricAccrualConfig?.withNightTarif} readings={endPeriodReadings}/>
            <br/>
            { endInterval?.getDate() === datesInterval?.endDate?.getDate() && gskMeter && endPeriodReadings &&
              <>
                {endPeriodReadings.dayValue !== gskMeter.dayValue && 
                  <Alert type="error" closable style={{marginTop: 5}} message= {
                    <>Дневные показания на <b>{toMoneyString(Math.abs(endPeriodReadings.dayValue - gskMeter.dayValue), false)}</b> кВт&nbsp;
                    {endPeriodReadings.dayValue > gskMeter.dayValue ? 'больше' : 'меньше'} показаний общего счетчика
                    </>
                  }/>
                }
                {endPeriodReadings.nightValue!== undefined && gskMeter.nightValue !== undefined && endPeriodReadings.nightValue !== gskMeter.nightValue && 
                  <Alert type="error" closable style={{marginTop: 5}} message= {
                    <>Ночные показания на <b>{toMoneyString(Math.abs(endPeriodReadings.nightValue - gskMeter.nightValue), false)}</b> кВт&nbsp;
                    {endPeriodReadings.nightValue > gskMeter.nightValue ? 'больше' : 'меньше'} показаний общего счетчика
                    </>
                  }/>
                }
              </> 
            
            }
          </td>
        </tr>
      </tbody></table>
    
    { consumption && <>
      <DividerHr>Потребление за выбранный период</DividerHr>
      <MeterReadingsBlock withNightTarif={electricAccrualConfig?.withNightTarif} readings={consumption}/>
    </>
    }

    {topConsumptionsOptions &&
      <HighchartsReact
        highcharts={Highcharts}
        options={topConsumptionsOptions}
      />
    }
    
    <DividerHr>Детализация</DividerHr>
    <Table
      loading={isLoading}
      size="small"
      columns={columns}
      dataSource={page.items}
      rowKey={r => r.meterId}
      pagination={{
        current: page.page,
        defaultPageSize: PAGE_SIZE,
        pageSize: page.pageSize,
        total: page.totalCount,
        onChange: (page, size) => loadPage(page, size)
      }}
    />
    </>}
    {editGskMeterDialog}
  </Card>
}

interface MeterReadingsBlockProps{
  readings?: ElectricMetersReadingsSum;
  withNightTarif?: boolean;
}
const MeterReadingsBlock = ({readings, withNightTarif = false}: MeterReadingsBlockProps) => {
  return <>{readings ? 
    <>
      <span style={{display:'inline-block'}}><Tooltip title='День'><DayIcon/></Tooltip>: <b>{toMoneyString(readings.dayValue, false)}</b> кВт &nbsp; </span>
      {withNightTarif && readings.nightValue !== undefined && <span style={{display:'inline-block'}}>
        <Tooltip title='Ночь'><NightIcon/></Tooltip>: <b>{toMoneyString(readings.nightValue, false)}</b> кВт</span>}
    </>
    : <SyncOutlined spin /> }
  </>
}