import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Link } from "react-router-dom";

export default function InviteGskUserPage() {
  return <>
    <h1>Зачем это нужно?</h1>
    <p>Пользователи, принявшие приглашения: 
      <ul>
        <li>Получают доступ к членской книжке, новостям и документам на сайте ГСК</li>
        <li>Видят свои долги и могут оплатить их онлайн</li>
        <li>Могут участвовать в голосованиях</li>
        <li>Будут получать уведомления на почту при появлении новых начислений и новостей</li>
      </ul>
    </p>
    
    <h1>Необходимые права</h1>
    <p>Чтобы пригласить пользователя нужно что бы у Вас была <Link to="../gskRoles">роль</Link> <b>Председатель</b> или <b>Администратор</b></p>

    <h1>Приглашение пользователя</h1>
    <p>Перейдите в раздел <b>Боксы</b> и нажмите кнопку <Button size="small">Пригласить</Button>. Появится окно с ссылкой,
    которую нужно передать пользователю (например по почте, через Telegram или WhatsApp).</p>
    <p>Пользователь после перехода по ссылке попадет на сайт МойГСК.рф. Ему будет предложено зарегистрироваться или войти под своей учетной записью
    и принять Ваше приглашение. После этого пользователь получит доступ к разделу ГСК и боксу, указанному в приглашении.
    </p>

    <h1>У пользователя несколько боксов?</h1>
    <p>Нет проблем! За одним пользователем может быть закреплено множество боксов.</p>
    <p>Сформируйте ссылки приглашения для каждого бокса и отправьте их пользователю, как это описано выше. Пользователю нужно будет
       зарегистрируется всего 1 раз сайте (или войти под своей учетной записью, если он уже зарегистрирован) и принять приглашения.</p>
    <p>Более того, пользователь с одной учетной записью может состоять в нескольких ГСК, и иметь множество боксов</p>

    <h1>Удаление пользователя</h1>
    <p>Выберите бокс, от которого нужно отвязать пользователя и нажмите кнопку <b>Редактировать</b><EditOutlined/></p>
    <p>Справа от ФИО и телефона пользователя нажмите кнопку <b>Удалить</b><DeleteOutlined/> и следуйте инструкциям</p>
    <p>Если у пользователя больше нет привязанных боксов он потеряет доступ к ГСК</p>

    <h1>Пользователь продал бокс, что делать?</h1>
    <p>Сначала удалите пользователя из бокса, а затем отправьте приглашение новому владельцу</p>
  </>
}