import { Form, Input, InputNumber, Space, Switch } from "antd"
import GroupBox from "../../GroupBox/GroupBox"
import RowTwoCol from "../../RowTwoCol/RowTwoCol"
import { ElectricAccrualConfigModel, METER_VALUE_LIMIT } from "../../../types/MeterTypes"
import { FormInstance } from "antd/lib";
import { toFloatString } from "../../../utils/numberHelper";
import { AccrualType } from "../../../types/GskChargeTypes";
import { AccrualFormData } from "./EditAccrualModal";
import { ShowError } from "../../Notifications/Notifications";
import { useMemo } from "react";
import FloatInput from "../../Controls/FloatInput";
import { NumInputValidator } from "../../../utils/NumInputValidator";
import { DayIcon, NightIcon } from "../../icons";
import IconButton from "../../IconButton/IconButton";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface AccrualElectricPartialProps {
  form: FormInstance;
  config: ElectricAccrualConfigModel | undefined;
}

export const AccrualElectricPartial = ({form, config}: AccrualElectricPartialProps) => {
  
	const withNightTarif: boolean = Form.useWatch('withNightTarif', form) ?? false;
  const initDayValue = (Form.useWatch('initDayValue', form) ?? 0);
  const initNightValue = (Form.useWatch('initNightValue', form) ?? 0);
  const prevDayMeterValue = Math.max(config?.dayMeterValue ?? 0, initDayValue, 0);
  const prevNightMeterValue = Math.max(config?.nightMeterValue ?? 0, initNightValue, 0);

	const dayValue = (Form.useWatch('dayMeterValue', form) ?? -1);
  const nightValue = (Form.useWatch('nightMeterValue', form) ?? -1);
  const dayDiff = useMemo(() => dayValue > METER_VALUE_LIMIT ? undefined : dayValue - prevDayMeterValue, [dayValue, prevDayMeterValue]);
  const nightDiff = useMemo(() => nightValue > METER_VALUE_LIMIT || nightValue === null || nightValue === undefined ? undefined : nightValue - prevNightMeterValue
  , [nightValue, prevNightMeterValue]);

  return (
    <>
      <Form.Item noStyle hidden name='accrualId'>
        <Input />
      </Form.Item>
      <Form.Item label='Двухтарифный' tooltip='C ночным тарифом' name='withNightTarif' valuePropName="checked">
        <Switch />
      </Form.Item>

      <GroupBox label={<Space>Начальные показания <IconButton icon={<QuestionCircleOutlined/>} title='Эти значения считаются оплаченным и не будут участвовать в расчете начислений'/></Space>}>
        <RowTwoCol
          left={<Form.Item 
                  label={<>День<DayIcon/></>}
                  name='initDayValue'
                  rules={[{required: true, message: "Введите значение"}]}
                >
                  <InputNumber min={0} max={METER_VALUE_LIMIT} addonAfter='кВт' />
                </Form.Item>}
            right={withNightTarif && 
                <Form.Item 
                  label={<>Ночь<NightIcon/></>}
                  name='initNightValue'
                  rules={[{required: withNightTarif, message: "Введите значение"}]}
                >
                  <InputNumber min={0} max={METER_VALUE_LIMIT} addonAfter='кВт' />
                </Form.Item>}
        />
      </GroupBox>
      
      <GroupBox label="Текущие показания">
        <RowTwoCol
          left={<Form.Item 
                  label={<>День<DayIcon/></>}
                  name='dayMeterValue'
                  rules={[NumInputValidator(0, METER_VALUE_LIMIT, true)]}
                  validateStatus={dayDiff !== undefined && dayDiff < 0 ? 'warning' : 'validating'}
                  help={dayDiff !== undefined ? <>{dayDiff > 0 && '+'}{toFloatString(dayDiff)} кВт</> : undefined}
                >
                  <InputNumber addonAfter='кВт' />
                </Form.Item>}
          right={withNightTarif && 
                <Form.Item 
                  label={<>Ночь<NightIcon/></>}
                  name='nightMeterValue'
                  rules={[NumInputValidator(0, METER_VALUE_LIMIT, withNightTarif)]}
                  validateStatus={nightDiff !== undefined && nightDiff < 0 ? 'warning' : 'validating'}
                  help={nightDiff !== undefined ? <>{nightDiff > 0 && '+'}{toFloatString(nightDiff)} кВт</> : undefined}
                >
                  <InputNumber addonAfter='кВт' />
                </Form.Item>}
        />
      </GroupBox>
      <GroupBox label="Стоимость 1 кВт">
        <RowTwoCol
          left={<Form.Item label={<>День<DayIcon/></>} name='dayUnitPrice' rules={[NumInputValidator(0.01, 999, true)]}>
                  <FloatInput addonAfter='₽' />
                </Form.Item>}
          right={withNightTarif && 
                <Form.Item 
                  label={<>Ночь<NightIcon/></>}
                  name='nightUnitPrice'
                  rules={[NumInputValidator(0.01, 999, withNightTarif)]}
                >
                  <FloatInput addonAfter='₽' />
                </Form.Item>}
        />
      </GroupBox>
    </>
  )
}

export const ValidateElectricAccrualConfig = (data: Partial<ElectricAccrualConfigModel>) => {
  if (data.withNightTarif === undefined) {
		ShowError('Режим счетчика (двухтарифный\\однотарифный) не указан');
		return;
	}
	if (data.initDayValue === undefined) {
		ShowError('Начальные показания (День) не указаны');
		return;
	}
	if (data.dayMeterValue === undefined) {
		ShowError('Текущие показания (День) не указаны');
		return;
	}
	if (data.dayUnitPrice === undefined) {
		ShowError('Стоимость 1 кВт (День) не указана');
		return;
	}
	
	if (data.withNightTarif)
	{
			if (data.initNightValue === undefined)
			{
				ShowError('Начальные показания (Ночь) не указаны');
				return;
			}
			if (data.nightMeterValue === undefined)
			{
				ShowError('Текущие показания (Ночь) не указаны');
				return;
			}
			if (data.nightUnitPrice === undefined)
			{
				ShowError('Стоимость 1 кВт (Ночь) не указана');
				return;
			}
	}
}

export const getElectricAccrualConfig = (data: AccrualFormData): ElectricAccrualConfigModel | undefined => {
	if (data.accrualType === undefined) {
		ShowError("Вид начисления не указан");
		return;
	}

	if (data.accrualType !== AccrualType.ELECTRIC) {
		return undefined;
	}
	ValidateElectricAccrualConfig(data);

	return {
    accrualId: data.accrualId!,
		withNightTarif: data.withNightTarif!,
		initDayValue: data.initDayValue!,
		initNightValue: data.initNightValue,
		dayMeterValue: data.dayMeterValue!,
		nightMeterValue: data.nightMeterValue,
		dayUnitPrice: data.dayUnitPrice!,
		nightUnitPrice: data.nightUnitPrice,
	}
};