import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Flex, List,  Modal,  Space,  Spin, Tooltip } from "antd";
import { useDialogWithParameter } from "../../../utils/useDialog";
import { ElectricMeterModel } from "../../../types/MeterTypes";
import EditElectricMeterModal from "./EditElectricMeterModal";
import { useEffect, useMemo, useState } from "react";
import { ElectricMetersService } from "../../../Services/ElectricMetersService";
import GroupBox from "../../GroupBox/GroupBox";
import IconButton from "../../IconButton/IconButton";
import { CounterIcon, DayIcon, NightIcon, RestoreIcon } from "../../icons";
import ElectricMetersReadingsModal from "./ElectricMetersReadingsModal";
import useLoading from "../../../utils/hooks/useLoading";
import { GskBox } from "../../../types/GskTypes";

interface Props {
  canEdit: boolean;
  box: GskBox;
}
export default function BoxMetersList({canEdit, box}: Props) {
  const [isMetersLoading, load] = useLoading();
  const [meters, setMeters] = useState<ElectricMeterModel[]>([]);
  const [showDeleted, setShowDeleted] = useState(false);
  const electricMeters = useMemo(() => meters.filter(m => (showDeleted && m.isDeleted) || !m.isDeleted), [meters, showDeleted])
  const isLoading = isMetersLoading;

  const containsIsDeleted = useMemo(() => meters.some(m => m.isDeleted), [meters]);
  
  const [editElectricMeterModal, openEditElectricMeterModal] = useDialogWithParameter<ElectricMeterModel | null>(
    (param, closeEditMeterModal) => <EditElectricMeterModal box={box} closeDialog={closeEditMeterModal} meter={param} setMeters={setMeters} />
  )

  const refreshMeter = async (meterId: number) => {
    const resp = await load(ElectricMetersService.getMeter(box.gskId, meterId));
    if (resp) {
      setMeters(prev => [...prev.filter(p => p.id !== resp.id), resp]);
    }
  }

	const [electricMetersReadingsModal, openElectricMetersReadingsModal] = useDialogWithParameter<ElectricMeterModel>(
		(param, closeDialog) => <ElectricMetersReadingsModal 
      meter={param}
      box={box}
      closeDialog={closeDialog}
      refreshMeter={() => refreshMeter(param.id)} 
    />
	);

  const electricMetersSorted = useMemo(() => electricMeters.sort((a,b) => b.id - a.id), [electricMeters]);

  useEffect(() => {
    const loadMeters = async () => {
      const resp = await load(ElectricMetersService.getBoxMeters(box.gskId, box.id));
      if (resp) {
        setMeters(resp);
      }
    }
    loadMeters();
  }, [box.gskId, box.id, load]);


  return (<Spin spinning={isLoading}>
    {containsIsDeleted && <Space>
      <Checkbox checked={showDeleted} onChange={e => setShowDeleted(e.target.checked)}>Показанить удаленные</Checkbox>
    </Space>}
    <GroupBox label='Электроэнергия'>
      <List size="small" dataSource={electricMetersSorted}
        renderItem={m => <ElectricMeterItem
          gskId={box.gskId}
          key={m.id}
          meter={m}
          setMeters={setMeters}
          openEditElectricMeterModal={openEditElectricMeterModal}
          openElectricMetersReadingsModal={() => openElectricMetersReadingsModal(m)}
        />}
      />
      
      <Flex justify='flex-end'>
        <Button 
          icon={<PlusCircleOutlined />} 
          disabled={!canEdit}
          title={canEdit ? '' : 'Доступно пользователям с ролью "Председатель" или "Администратор"'}
          onClick={() => openEditElectricMeterModal(null)}
        >Добавить</Button>
      </Flex>
    </GroupBox>
    {editElectricMeterModal}
    {electricMetersReadingsModal}
  </Spin>)
}

interface ElectricMeterItemProps{
  gskId: number;
  meter: ElectricMeterModel;
  openEditElectricMeterModal: (v: ElectricMeterModel | null) => void;
  openElectricMetersReadingsModal: (v: ElectricMeterModel) => void;
  setMeters: React.Dispatch<React.SetStateAction<ElectricMeterModel[]>>;
}
const ElectricMeterItem = ({gskId, meter, openEditElectricMeterModal, openElectricMetersReadingsModal, setMeters}: ElectricMeterItemProps) => {
  const actions = useMemo(() => {
    const handelRestore = async () => {
      Modal.confirm({
        title: "Восстановление счетчика",
        content: <>Отменить удление счетчика электроэнергии <b>№{meter.num}</b>?</>,
        okText: "Да",
        cancelText: 'Отмена',
        onOk: async () => {
          const resp = await ElectricMetersService.restoreMeter(gskId, meter.id);
          if (resp) {
            setMeters(prev => [...prev.filter(p => p.id !== meter.id), resp]);
          }
        }
      });
    }

    if (meter.isDeleted) {
      return [<IconButton icon={<RestoreIcon />} onClick={handelRestore}/>];
    }
    return [
      <IconButton icon={<CounterIcon />} title="Показания" onClick={() => openElectricMetersReadingsModal(meter)}/>,
      <IconButton icon={<EditOutlined />} title="Изменить" onClick={() => openEditElectricMeterModal(meter)}/>,
    ];
  }, [gskId, meter, openEditElectricMeterModal, openElectricMetersReadingsModal, setMeters]);

  return (
    <List.Item
      title={meter.isDeleted ? 'Счетчик удален' : undefined}
      actions={actions}
    >
      <List.Item.Meta 
        title={<span style={{textDecoration: meter.isDeleted ? 'line-through' : undefined}}>№ {meter.num}</span>}
        description={<><Tooltip title='День'><DayIcon/></Tooltip>: {meter.lastDayValue}кВт &nbsp; &nbsp; {meter.withNightTarif && <> <Tooltip title='Ночь'><NightIcon/></Tooltip>: {meter.lastNightValue}кВт</>}</>}
      />
    </List.Item>
  )
}