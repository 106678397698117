import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Gsk, GskBox } from "../../types/GskTypes";
import useCurrentGsk from "./useCurrentGsk";
import { Button, Card, Flex, Form, Grid, Input, Pagination, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { DollarOutlined, PlusCircleOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { toMoneyString } from "../../utils/moneyHelper";
import { AssignedUser } from "../../components/Gsk/EditBoxModal";
import RowTwoCol from "../../components/RowTwoCol/RowTwoCol";
import useLoading from "../../utils/hooks/useLoading";
import { ElectricMetersService } from "../../Services/ElectricMetersService";
import { BoxModelWithMeters, ElectricMeterModel, GetMeterTypeLabel } from "../../types/MeterTypes";
import { DayIcon, LampIcon, MeterIcon, NightIcon, ReportIcon } from "../../components/icons";
import { useDialogWithParameter } from "../../utils/useDialog";
import NewElectricMeterReadingModal from "../../components/Gsk/Meters/NewElectricMeterReadingModal";
import {default as AntdLink}  from "antd/lib/typography/Link" ;
import { Link, useNavigate } from "react-router-dom";
import EditElectricMeterModal from "../../components/Gsk/Meters/EditElectricMeterModal";
import ElectricMetersReadingsModal from "../../components/Gsk/Meters/ElectricMetersReadingsModal";
import { MetersPageTour } from "../../components/Tours/Gsk/MetersPageTour";
import { RefType, setAppRef } from "../../store/enum/RefType";
import IconButton from "../../components/IconButton/IconButton";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import { EmptyPagedResult, PagedResult } from "../../types/dto";

export default function MetersPage() {
	const gsk = useCurrentGsk();
  return (<>{gsk ? <Page gsk={gsk}/> : <>Загрузка...</>}</>)
}

interface PageProps{
  gsk: Gsk;
}
interface ElectricMeterModelExtended extends ElectricMeterModel{
  isFirstRow: boolean;
}
interface Box extends GskBox {
  meter: ElectricMeterModelExtended | null;
  rowSpan: number;
};

const PAGE_SIZE = 10;
function Page({gsk}: PageProps)
{
  const {sm, md} = Grid.useBreakpoint();
  const [noBoxes, setNoBoxes] = useState(true);
	const addMeterRef = useRef(null);
	const meterNumRef = useRef(null);
	const readingsRef = useRef(null);
	const addReadingsRef = useRef(null);
  const nav = useNavigate();

  const [isLoading, load] = useLoading();
  const [page, setPage] = useState<PagedResult<BoxModelWithMeters>>(EmptyPagedResult<BoxModelWithMeters>(PAGE_SIZE));

  const [filterText, setFilterText] = useState('');

  const loadPage = useCallback(async (page: number, pageSize: number) => {
    const resp = await load(ElectricMetersService.getBoxesWithMeters(gsk.id, { page, pageSize, filterText}));
    if (resp) {
      if (noBoxes && resp.items.length > 0) {
        setNoBoxes(false);
      }
      setPage(resp)
    }
  }, [noBoxes, filterText, gsk.id, load]);

  const refreshPage = useCallback(() => loadPage(page.page, page.pageSize), [loadPage, page.page, page.pageSize]);

  const onFilterEnter = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e || e.key === 'Enter') {
      refreshPage();
    }
  }

  const [editElectricMeterModal, openEditElectricMeterModal] = useDialogWithParameter<{box: GskBox, meter: ElectricMeterModel | null}>(
    (param, closeEditMeterModal) => <EditElectricMeterModal
      setMeters={refreshPage}
      closeDialog={closeEditMeterModal}
      meter={param.meter}
      box={param.box}
    />
  );

  const [newElectricReadingModal, openNewElectricReadingModal] = useDialogWithParameter<{meter: ElectricMeterModel, box: GskBox}>(
    (param, closeEditMeterModal) => <NewElectricMeterReadingModal
      closeDialog={closeEditMeterModal}
      meter={param.meter}
      box={param.box}
      refreshMeter={refreshPage}
    />
  );

  const [electricMetersReadingsModal, openElectricMetersReadingsModal] = useDialogWithParameter<Box>(
		(param, closeDialog) => <ElectricMetersReadingsModal 
      meter={param.meter!}
      box={param}
      closeDialog={closeDialog}
      refreshMeter={refreshPage} 
    />
	);

  const pageItems = useMemo(() => {
    if (!page.items.length) {
      return [];
    }
    let result: Box[] = [];
    let isFirstMeterRow = true;
    page.items
      .forEach(box => {
        if (box.meters.length === 0) {
          result.push({...box, meter: null, rowSpan: 1});
        } else {
          box.meters.forEach((m, index) => {
            result.push({...box, meter: {...m, isFirstRow: isFirstMeterRow}, rowSpan: index === 0 ? box.meters.length : 0});
            isFirstMeterRow = false;
          });
        }
      });
    return result;
  }, [page.items]);

  const columns: ColumnsType<Box> = useMemo(() => {
    const renderValue = (box: Box) => {
      if (!box.meter) {
        return <></>;
      }
      const meter = box.meter;
      return <RowTwoCol
        left={<AntdLink 
          ref={meter.isFirstRow ? readingsRef : undefined} 
          onClick={() => openElectricMetersReadingsModal(box)}
        >
          <Tooltip title='День'><DayIcon/></Tooltip>: {meter.lastDayValue} {meter.withNightTarif && <>&nbsp; <Tooltip title='Ночь'><NightIcon/></Tooltip>: {meter.lastNightValue}</>}
        </AntdLink>}
        right={<IconButton ref={meter.isFirstRow ? addReadingsRef : undefined} title="Добавить показание" icon={<PlusCircleOutlined />} 
        onClick={() => openNewElectricReadingModal({meter, box})}/>}
        rightSize={30}
      />
    }

    const t: ColumnsType<Box> = [
    {
      title: '№',
      key: 'num',
      dataIndex: 'num',
      width: 50,
      onCell: row => ({
        rowSpan: row.rowSpan
      }),
    },
    {
      title: 'Владелец',
      key: 'ownerName',
      dataIndex: 'ownerName',
      render: (_, box) => <AssignedUser box={box} showBoxOwner={true}/>,
      responsive: ['md'],
      onCell: row => ({
        rowSpan: row.rowSpan,
      }),
    },
    {
      title: 'Счетчики',
      children: [
        {
          title: 'Тип',
          key: 'meterType',
          render: (_, row) => <RenderMeterTypeCell type={row.meter?.type} />,
          onCell: row => ({
            className: (row.meter?.debt ?? 0) > 0 ? 'late-payment' : ''
          }),
        },
        {
          title: 'Номер',
          key: 'num',
          dataIndex: 'num',
          render: (_, row) => 
            <AntdLink
              ref={row.meter?.isFirstRow ? meterNumRef : undefined}
              onClick={() => row.meter ? openEditElectricMeterModal({box: row, meter: row.meter}) : null}
            >
              {row.meter?.num}
            </AntdLink>,
          onCell: row => ({
            className: (row.meter?.debt ?? 0) > 0 ? 'late-payment' : ''
          }),
        },
        {
          title: 'Показания',
          key: 'value',
          render: (_, row) => renderValue(row),
          onCell: row => ({
            className: (row.meter?.debt ?? 0) > 0 ? 'late-payment' : ''
          }),
        },
        {
          title: 'Долг',
          key: 'debt',
          width: 100,
          render: (_, row) => <>{(row.meter?.debt ?? 0) > 0 && <>{toMoneyString(row.meter?.debt ?? 0)}</>}</>,
          hidden: !md,
          onCell: row => ({
            className: (row.meter?.debt ?? 0) > 0 ? 'late-payment' : ''
          }),
        },
    ]},
    {
      title: <Flex justify="flex-end">
        <IconButton 
          title="Обновить"
          icon={<SyncOutlined/>} 
          onClick={refreshPage} 
        />
      </Flex>,
      key: 'actions',
      render: (_, row, index) =>
        <Space>
          <IconButton 
            icon={<MeterIcon/>}
            ref={index === 0 ? addMeterRef : undefined}
            title="Добавить счетчик" 
            onClick={() => openEditElectricMeterModal({box: row, meter: null})} 
          />
          <Tooltip title="Начисления"><Link style={{color: 'unset'}} to={'../Cash/' + row.id}><DollarOutlined /></Link></Tooltip>
        </Space>,
      onCell: (row) => ({
        rowSpan: row.rowSpan,
        align: 'right'
      }),
    },
  ]
  return t;
  }, [md, openEditElectricMeterModal, openElectricMetersReadingsModal, openNewElectricReadingModal, refreshPage]);
  
	useEffect(() => { setAppRef(RefType.GskMetersFirstRowAddMeter, addMeterRef); }, [addMeterRef]);
	useEffect(() => { setAppRef(RefType.GskMetersFirstMeterNum, meterNumRef); }, [meterNumRef]);
	useEffect(() => { setAppRef(RefType.GskMetersFirstRowMeterReadings, readingsRef);}, [readingsRef]);
	useEffect(() => { setAppRef(RefType.GskMetersFirstRowMeterAddReadings, addReadingsRef); }, [addReadingsRef]);

  useEffectOnce(() => { loadPage(1, PAGE_SIZE); });

  return (
    <Space direction="vertical" >
      <Card size="small" loading={isLoading}>
        <RowTwoCol
          left={
              <Form layout={sm ? 'inline' : 'vertical'} size="small">
                <Form.Item label='Фильтр'>
                  <Input value={filterText} onChange={e => setFilterText(e.target.value.trim())} onKeyUp={onFilterEnter} placeholder="№ / ФИО" />
                </Form.Item>
              </Form>
            }
          right={
            <Space style={{height: '100%'}}>
              <Flex justify="flex-end" align="end">
                <Button icon={<SearchOutlined/>} onClick={() => onFilterEnter()}>Поиск</Button>
              </Flex>
              <Button icon={<ReportIcon/>} onClick={() => nav('../Reports/ElectricMeters')}>Отчет</Button>
              <MetersPageTour noBoxes={noBoxes} />
            </Space>}
          rightSize={220}
        />
      </Card>
      <Card>
        <Table
          loading={isLoading}
          size="small"
          columns={columns}
          dataSource={pageItems}
          rowKey={r => r.meter ? 'meter_' + r.meter.id : 'box_' + r.id}
          pagination={false}
        />
        <Flex justify="flex-end" style={{paddingTop: 8}}>
          <Pagination 
            size="small"
            current={page.page} 
            defaultPageSize={PAGE_SIZE}
            pageSize={page.pageSize}
            total={page.totalCount}
            onChange={(page, size) => loadPage(page, size)}
          />
        </Flex>
      </Card>
    {editElectricMeterModal}
    {newElectricReadingModal}
    {electricMetersReadingsModal}
    </Space>
  )
}

interface RenderMeterTypeCellProps  {
  type?: string;
}
const RenderMeterTypeCell =({type} : RenderMeterTypeCellProps) => {
  const { md } = Grid.useBreakpoint();
  if (!type) {
    return (<></>);
  }
  const text = GetMeterTypeLabel(type);
  const icon = (() => {switch (type) {
      case 'Electric':
        return <LampIcon />;
      default:
        throw Error("Unexpected meter type: " + type);
    }
  })();
  if (md) {
    return <Space>{icon}{text}</Space>
  } else {
    return <Tooltip title={text}>{icon}</Tooltip>
  }
}