import ModalBase from "../../ModalBase/ModalBase";
import { useForm } from "antd/es/form/Form";
import { ElectricAccrualConfigModel } from "../../../types/MeterTypes";
import useLoading from "../../../utils/hooks/useLoading";
import { AccrualElectricPartial, ValidateElectricAccrualConfig } from "../Charges/AccrualElectricPartial";
import { GskChargeService } from "../../../Services/GskChargeService";
import { Form } from "antd";

interface Props{
  gskId: number;
	closeDialog: CallableFunction;
  config: ElectricAccrualConfigModel;
	onFinished: (v: ElectricAccrualConfigModel) => void;
}
export function EditGksElectricMeterModal({gskId, config, closeDialog, onFinished}: Props) {
  
	const [isLoading, load] = useLoading();
	const [form] = useForm<ElectricAccrualConfigModel>();

  form.setFieldsValue(config);
  const handleOk = async (data: ElectricAccrualConfigModel) => {
    ValidateElectricAccrualConfig(data);

    const result = await load(GskChargeService.editElectricAccrualConfig(gskId, data));
    if (result) {
      closeDialog();
      onFinished(result);
    }
	}

  return <ModalBase 
    closeDialog={closeDialog}
    title={"Общий счетчик электроэнергии ГСК"}
    okButtonHandler={() => form.submit()}
    isLoading={isLoading}
    width={600}
  >
    <Form
      form={form}
      labelCol={{span: 8}}
      wrapperCol={{span:16}}
      size='small'
      onFinish={handleOk}
    >
      <AccrualElectricPartial config={config} form={form}/>
    </Form>
  </ModalBase>
}